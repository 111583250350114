'use strict';

import { mathUtil } from 'javascripts/global/lib/math_util';
import { validatesEmail } from 'javascripts/global/lib/validator';
import {
  normalizeCustomDecimalValue,
  normalizeCustomNumberValue,
  removeUnitAndComma,
} from '../../lib/form_tool';
import { i18n } from 'javascripts/global/i18n/i18n';

const EXPORT_AUDIT_LOG_SPECIFY_EARLIEST_DATE = '2022/01/01';

const valGetParentContainer = function (element) {
  if ($(element).closest('.form-group-sub').length > 0) {
    return $(element).closest('.form-group-sub');
  } else if ($(element).closest('.bootstrap-select').length > 0) {
    return $(element).closest('.bootstrap-select');
  } else {
    return $(element).closest('.form-group');
  }
};

// 日付同士の比較を行う際、時刻の影響が現れないように時刻部分を切り捨てる。
const truncateDate = (date) => {
  return new Date(date.getFullYear(), date.getMonth(), date.getDate());
};

jQuery.validator.setDefaults({
  errorElement: 'div', // default input error message container
  focusInvalid: false, // do not focus the last invalid input
  ignore: '', // validate all fields including form hidden input

  errorPlacement: function (error, element) { // render error placement for each input type
    const group = valGetParentContainer(element);
    const help = group.find('.form-text');

    if (group.find('.valid-feedback, .invalid-feedback').length !== 0) {
      return;
    }

    element.addClass('is-invalid');
    error.addClass('invalid-feedback');

    if (help.length > 0) {
      help.before(error);
    } else {
      if (element.closest('.bootstrap-select').length > 0) { // Bootstrap select
        element.closest('.bootstrap-select').wrap('<div class="bootstrap-select-wrapper" />').after(error);
      } else if (element.closest('.input-group').length > 0) { // Bootstrap group
        if (element.parent('.with-unit-suffix').length > 0) { // input要素の直後に単位を表示する場合
          element.next().after(error);
        } else {
          element.after(error);
        }
      } else if (element.closest('.form-group-sub').length > 0) { // 複数フォームで一つの form-group として扱う場合
        $(group).parent().append(error);
      } else if (element.closest(`.select-items-field.form-group`).length > 0) { // カスタム項目選択肢のフォーム
        $(element).closest('.nested-fields').after(error);
      } else { // Checkbox & radios
        if (element.is(':checkbox')) {
          element.closest('.kt-checkbox').find('> span').after(error);
        } else if (element.is(':radio')) {
          // elementの親と親の親がいるかを確認
          if (element.parent() && element.parent().parent()) {
            // ラジオボタン型の場合、親の親の下にエラーを追加する
            // 「親の親」は複数のラジオボタンをまとめた要素になります
            // これを追加しない場合、エラーメッセージがラジオボタン1個目の下に表示される
            element.parent().parent().after(error);
          } else {
            // 親の親がいない場合、通常のエラー文を追加する
            // 現在はこちらの処理は通らないと思われるが、念のため残しておく
            element.after(error);
          }
        } else {
          element.after(error);
        }
      }
    }
  },

  highlight: function (element) { // highlight error inputs
    const group = valGetParentContainer(element);
    group.addClass('validate');
    group.addClass('is-invalid');
    $(element).addClass('is-invalid');
    $(element).removeClass('is-valid');
  },

  unhighlight: function (element) { // revert the change done by highlight
    const group = valGetParentContainer(element);
    group.removeClass('validate');
    group.removeClass('is-invalid');
    $(element).removeClass('is-invalid');
  },

  success: function (label, element) {
    const group = valGetParentContainer(element);
    group.removeClass('validate');
    group.find('.invalid-feedback').remove();
  },
});

jQuery.validator.addMethod('alphabetAndNumber', (value, element) => /^[a-zA-Z0-9]*$/.test(value), i18n.t('validator.only_alnum'));

jQuery.validator.addMethod('email', validatesEmail, i18n.t('validator.invalid_email'));

/* eslint no-irregular-whitespace: ["error", { "skipRegExps": true }]*/ // 全角スペースを許可するため
jQuery.validator.addMethod('zipCode1', (value, element) => /^[ 　]*[0-9０-９]{3}[ 　]*$|^[ 　]*$/.test(value), i18n.t('validator.enter_num_digits', { num: 3 }));

jQuery.validator.addMethod('zipCode2', (value, element) => /^[ 　]*[0-9０-９]{4}[ 　]*$|^[ 　]*$/.test(value), i18n.t('validator.enter_num_digits', { num: 4 }));

jQuery.validator.addMethod('showOrderAboveOne', (value, element) => /^[ 　]*[1-9１-９][0-9０-９,]*[ 　]*$|^[ 　]*$/.test(value), i18n.t('validator.enter_more_than_num', { num: 1 }));

jQuery.validator.addMethod('bankCode', (value, element) => /^[ 　]*[0-9０-９]{1,4}(\s（.*）)*[ 　]*$|^[ 　]*$/.test(value), i18n.t('validator.select_from_bank_code'));

jQuery.validator.addMethod('branchCode', (value, element) => /^[ 　]*[0-9０-９]{1,3}(\s（.*）)*[ 　]*$|^[ 　]*$/.test(value), i18n.t('validator.select_from_branch_code'));

jQuery.validator.addMethod('bankNumber', (value, element) => /^[ 　]*[0-9０-９]{4,7}[ 　]*$|^[ 　]*$/.test(value), i18n.t('validator.enter_min_to_max_digits', { min: 4, max: 7 }));

jQuery.validator.addMethod('phone', (value, element) => /^[0-9]{2,5}$|^$/.test(value), i18n.t('validator.enter_min_to_max_digits', { min: 2, max: 5 }));

jQuery.validator.addMethod('numberAboveZero', (value, element) => /^[ 　]*[0０][ 　]*$|^[ 　]*[1-9１-９][0-9０-９,]*[ 　]*$|^[ 　]*$/.test(value), i18n.t('validator.enter_more_than_num', { num: 0 }));

jQuery.validator.addMethod('decimalAboveZero', (value, element) => /^[ 　]*[0０][ 　]*$|^[ 　]*[0-9０-９][0-9０-９,.]*[ 　]*$|^[ 　]*$/.test(value), i18n.t('validator.enter_more_than_num', { num: 0 }));

jQuery.validator.addMethod('moneyAboveZero', (value, element) => /^[ 　]*[0０][ 　]*$|^[ 　]*[1-9１-９][0-9０-９,]*[ 　]*$|^[ 　]*$/.test(value), i18n.t('validator.enter_more_than_num', { num: 0 }));

jQuery.validator.addMethod('moneyAboveOne', (value, element) => /^[ 　]*[1-9１-９][0-9０-９,]*[ 　]*$|^[ 　]*$/.test(value), i18n.t('validator.enter_more_than_num', { num: 1 }));

jQuery.validator.addMethod('moneyAboveFourthDecimalPlace', (value, element) => /^[ 　]*[0-9１-９][0-9０-９,]*(\.[0-9０-９]{0,4})?[ 　]*$|^[ 　]*$/.test(value), i18n.t('validator.enter_num_decimal', { num: 4 }));

jQuery.validator.addMethod('katakana', (value, element) => /^[ァ-ヴー\- ・]*$/.test(value), i18n.t('validator.only_katakana'));

jQuery.validator.addMethod('hiragana', (value, element) => /^[ぁ-んー\- ・]*$/.test(value), i18n.t('validator.only_hiragana'));

jQuery.validator.addMethod('bankAccountName', (value, element) => /^[ー-鿐﨑𡈽ヾヽ〜々ヶ〆〇ゝゞぁ-ゔァ-ヴー−0-9A-Za-z\(\)\.\-\/]*$/.test(value), i18n.t('validator.invalid_character_for_attribute', { attribute: i18n.t('attributes.account_name') }));

jQuery.validator.addMethod('bankAccountNameKana', (value, element) => /^[ァ-ヴー\- ・]*$/.test(value), i18n.t('validator.invalid_character_for_attribute', { attribute: i18n.t('attributes.account_name_kana') }));

// TODO: 法務省「商号の登記に用いることができる符号」の内容に沿った正規表現にする
jQuery.validator.addMethod('companyName', (value, element) => /^[ー-鿐ヾヽ〜々ヶ〆〇ゝゞぁ-ゔァ-ヴー0-9０-９&＆'’,，\-－.．･・A-ZＡ-Ｚa-zａ-ｚ 　]*$/.test(value), i18n.t('validator.invalid_character_for_attribute', { attribute: i18n.t('attributes.company_name') }));

jQuery.validator.addMethod('companyNameKana', (value, element) => /^[ァ-ヴ0-9ー\- ・]*$/.test(value), i18n.t('validator.invalid_character_for_attribute', { attribute: i18n.t('attributes.company_name_kana') }));

jQuery.validator.addMethod('jpIndustryMiddleCode', (value, element) => /^[ 　]*[0-9０-９]{2}[ 　]*$|^[ 　]*$/.test(value), i18n.t('validator.enter_num_digits', { num: 2 }));

jQuery.validator.addMethod('eGov', (value, element) => /^[ー-鿐ヾヽ〜々ヶ〆〇ゝゞぁ-ゔｦ-ﾟァ-ヴーA-ZＡ-Ｚa-zａ-ｚΑ-ω0-9０-９ 　！!”"＃#＄$％%＆&’'（(）)＊*＋+，,－\-．.／/：；;＜<＝=＞>？?＠@［\[\\］\]\^_`\{\|\}~、。・ ゙ ゚ ́ ̈¯ヽヾゝゞ〃仝々〆〇ー―‐∥"〔〕〈〉《》「」『』【】±×÷≠≦≧∞∴♂♀°′′′°C¥¢£§☆★○●◎◇◆□■△▲▽▼※〒→←↑↓〓∈∋⊆⊇⊂⊃∧∨¬⇒⇔∀∃⌒∂∇≪≫√∽∝Å‰♯♭♪†‡¶◯─│┌┐┘└├┬┤┴┼━┃┏┓┛┗┣┳┫┻╋┠┯┨┷┿┝┰┥┸╂]*$/.test(value), i18n.t('validator.invalid_character'));

jQuery.validator.addMethod('eGovKana', (value, element) => /^[ァ-ヴ0-9ー\- ・]*$/.test(value), i18n.t('validator.invalid_character'));

jQuery.validator.addMethod('personalName', (value, element) => /^[ー-鿐﨑𡈽ヾヽ〜々ヶ〆〇ゝゞぁ-ゔァ-ヴー− \-A-Za-z・]*$/.test(value), i18n.t('validator.invalid_character_for_attribute', { attribute: i18n.t('attributes.name') }));

jQuery.validator.addMethod('personalNamePlusSpace', (value, element) => /^[ 　ー-鿐﨑𡈽ヾヽ〜々ヶ〆〇ゝゞぁ-ゔァ-ヴー−\-A-Za-z・]*$/.test(value), i18n.t('validator.invalid_character_for_attribute', { attribute: i18n.t('attributes.name') }));

jQuery.validator.addMethod('personalNameKana', (value, element) => /^[ァ-ヴー\- ・]*$/.test(value), i18n.t('validator.invalid_character_for_attribute', { attribute: i18n.t('attributes.name_kana') }));

jQuery.validator.addMethod('personalNameSeimei', (value, element) => /^[ー-鿐﨑𡈽ヾヽ〜々ヶ〆〇ゝゞぁ-ゔァ-ヴー− ・A-Za-z .・ー]*$/.test(value), i18n.t('validator.invalid_character_for_attribute', { attribute: i18n.t('attributes.name') }));

jQuery.validator.addMethod('eGovPersonalName', (value, element) => /^[ー-鿐ヾヽ〜々ヶ〆〇ゝゞぁ-ゔァ-ヴー− \-A-Za-z・]*$/.test(value), i18n.t('validator.invalid_character_for_attribute', { attribute: i18n.t('attributes.name') }));

// JS でローマ数字をカバーできる良い正規表現が見つからなかったので 1(Ⅰ) ~ 12(Ⅻ)までとしている
jQuery.validator.addMethod('address', (value, element) => /^[ー-鿐ヾヽ〜々ヶ〆〇ゝゞぁ-ゔァ-ヴー− 0-9０-９Ⅰ-Ⅻⅰ-ⅻ&',\-.・A-ZＡ-Ｚa-zａ-ｚ 　]*$/.test(value), i18n.t('validator.invalid_character_for_attribute', { attribute: i18n.t('attributes.address') }));

jQuery.validator.addMethod('addressKana', (value, element) => /^[ァ-ヴ0-9０-９ー\- ・ 　]*$/.test(value), i18n.t('validator.invalid_character_for_attribute', { attribute: i18n.t('attributes.address_kana') }));


jQuery.validator.addMethod('foreignResidentCardName', (value, element) => /^[ 　]*[A-ZＡ-Ｚa-zａ-ｚ]*[ 　]*$/.test(value), i18n.t('validator.only_alphabet'));

jQuery.validator.addMethod('foreignResidentCardNumber', (value, element) => /^[ 　]*([A-ZＡ-Ｚa-zａ-ｚ][A-ZＡ-Ｚa-zａ-ｚ][0-9０-９]{8}[A-ZＡ-Ｚa-zａ-ｚ][A-ZＡ-Ｚa-zａ-ｚ]|[A-ZＡ-Ｚa-zａ-ｚ][0-9０-９]{8})[ 　]*$|^[ 　]*$/.test(value), i18n.t('validator.invalid_value'));

jQuery.validator.addMethod('koyoHokenNumber1', (value, element) => /^[ 　]*[0-9０-９]{4}[ 　]*$|^[ 　]*$/.test(value), i18n.t('validator.enter_num_digits', { num: 4 }));

jQuery.validator.addMethod('koyoHokenNumber2', (value, element) => /^[ 　]*[0-9０-９]{6}[ 　]*$|^[ 　]*$/.test(value), i18n.t('validator.enter_num_digits', { num: 6 }));

jQuery.validator.addMethod('koyoHokenNumber3', (value, element) => /^[ 　]*[0-9０-９]{1}[ 　]*$|^[ 　]*$/.test(value), i18n.t('validator.enter_num_digits', { num: 1 }));

jQuery.validator.addMethod('kisonenkinNumber1', (value, element) => /^[ 　]*[0-9０-９]{4}[ 　]*$|^[ 　]*$/.test(value), i18n.t('validator.enter_num_digits', { num: 4 }));

jQuery.validator.addMethod('kisonenkinNumber2', (value, element) => /^[ 　]*[0-9０-９]{6}[ 　]*$|^[ 　]*$/.test(value), i18n.t('validator.enter_num_digits', { num: 6 }));

jQuery.validator.addMethod('businessType', (value, element) => /^[ 　]*[0-9０-９]{2}[ 　]*$|^[ 　]*$/.test(value), i18n.t('validator.enter_num_digits', { num: 2 }));

jQuery.validator.addMethod('nenkinSeiriNumber1', (value, element) => /^[ 　]*[0-9０-９]{2}[ 　]*$|^[ 　]*$/.test(value), i18n.t('validator.enter_num_digits', { num: 2 }));

jQuery.validator.addMethod('nenkinSeiriNumber2', (value, element) => /^[ 　]*[ァ-ヴ]{1,4}[ 　]*$|^[ 　]*$/.test(value), i18n.t('validator.enter_num_chars_katakana', { num: 4 }));

jQuery.validator.addMethod('rodoHokenNumber1', (value, element) => /^[ 　]*[0-9０-９]{2}[ 　]*$|^[ 　]*$/.test(value), i18n.t('validator.enter_num_digits', { num: 2 }));

jQuery.validator.addMethod('rodoHokenNumber2', (value, element) => /^[ 　]*[0-9０-９]{1}[ 　]*$|^[ 　]*$/.test(value), i18n.t('validator.enter_num_digits', { num: 1 }));

jQuery.validator.addMethod('rodoHokenNumber3', (value, element) => /^[ 　]*[0-9０-９]{2}[ 　]*$|^[ 　]*$/.test(value), i18n.t('validator.enter_num_digits', { num: 2 }));

jQuery.validator.addMethod('rodoHokenNumber4', (value, element) => /^[ 　]*[0-9０-９]{6}[ 　]*$|^[ 　]*$/.test(value), i18n.t('validator.enter_num_digits', { num: 6 }));

jQuery.validator.addMethod('rodoHokenNumber5', (value, element) => /^[ 　]*[0-9０-９]{3}[ 　]*$|^[ 　]*$/.test(value), i18n.t('validator.enter_num_digits', { num: 3 }));

jQuery.validator.addMethod('kokuzeiNumber', function (value, element) {
  if (/^[ 　]*[0-9０-９]{13}[ 　]*$|^[ 　]*$/.test(value)) {
    const number = value.replace(/[ 　]/g, '').replace(/[０-９]/g, function (s) {
      return String.fromCharCode(s.charCodeAt(0) - 65248);
    });
    if (number === '') {
      return true;
    } else {
      const sumOfEvenDigits = Number(number[1]) + Number(number[3]) + Number(number[5]) + Number(number[7]) + Number(number[9]) + Number(number[11]); // eslint-disable-line max-len
      const sumOfOddDigits = Number(number[2]) + Number(number[4]) + Number(number[6]) + Number(number[8]) + Number(number[10]) + Number(number[12]); // eslint-disable-line max-len
      if (9 - (sumOfEvenDigits * 2 + sumOfOddDigits) % 9 === Number(number[0])) {
        return true;
      } else {
        return false;
      }
    }
  } else {
    return false;
  }
}, i18n.t('validator.invalid_value'));

jQuery.validator.addMethod('nenkinJigyoshoNumber', (value, element) => /^[ 　]*[0-9０-９]{5}[ 　]*$|^[ 　]*$/.test(value), i18n.t('validator.enter_num_digits', { num: 5 }));

jQuery.validator.addMethod('customFieldName', function (value, element) {
  if (/\|/.test(value)) {
    return false;
  } else {
    return true;
  }
}, i18n.t('validator.cannot_use_pipe'));

jQuery.validator.addMethod('physicalName', (value, element) => /^[ 　]*[a-zａ-ｚ0-9０-９_]*[ 　]*$/.test(value), i18n.t('validator.only_alnum_underscore'));

// 配偶者
jQuery.validator.addMethod('isSpouse', function (value, element) {
  // 未選択の場合、チェック処理を行わない
  // 未選択は undefined が送られる
  if (value) {
    let count = 0;
    $('.family-form').each((i, e) => {
      // 削除済みフォームは件数の対象外とする
      // 削除済みフォームには "1" が設定されている
      if ($(e).find('#destroy').val() === '1') {
        return;
      }
      const name = `"employee[families_attributes][${i}][is_spouse]"`;
      if ($(e).find(`[name=${name}]:visible`).prop('checked')) {
        count++;
      }
    });
    if (count > 1) {
      return false;
    }
  }
  return true;
}, i18n.t('validator.spouse_unique'));

// 所得税の扶養状況
jQuery.validator.addMethod('shotokuzeiFuyoType', function (value, element, index) {
  const targetFamily = $('.family-form')[index];
  // 配偶者特別控除対象者
  if (value === 'special' && $(targetFamily).find('#destroy').val() !== '1') {
    // チェックされていなければエラー
    return $(targetFamily).find(`#family_is_spouse_${index}`).prop('checked');
  }
  return true;
}, i18n.t('validator.kojo_only_spouse'));

// 所得の種類
jQuery.validator.addMethod('requireStudentIncomeType', function (value, element, key) {
  if (key == 'false') return true;

  $('.error-message').text('');
  if ($('[id$=is_student_employment_income]').prop('checked')) return true;
  if ($('[id$=is_student_business_income]').prop('checked')) return true;
  if ($('[id$=is_student_dividend_income]').prop('checked')) return true;
  if ($('[id$=is_student_estate_income]').prop('checked')) return true;

  $('.error-message').text(i18n.t('validator.select_income_type'));
  return false;
}, '');

jQuery.validator.addMethod('numberPlateHyobanchimei', (value, element) => /^[ー-鿐ヾヽ〜々ヶ〆〇ゝゞぁ-ゔァ-ヴー−\-・]*$/.test(value), i18n.t('validator.number_plate_hyobanchimei'));

jQuery.validator.addMethod('numberPlateBunruiNumber', (value, element) => /^[A-ZＡ-Ｚ0-9０-９]{0,3}$/.test(value), i18n.t('validator.number_plate_bunrui_number'));

jQuery.validator.addMethod('numberPlateHiragana', (value, element) => /^[ぁ-んー\- ・]*$/.test(value), i18n.t('validator.number_plate_hiragana'));

jQuery.validator.addMethod('numberPlateIchirenNumber', (value, element) => /^[・0-9０-９]{4}$|^$/.test(value), i18n.t('validator.number_plate_ichiren_number'));

jQuery.validator.addMethod('driverLicenseNumber', (value, element) => /^[0-9０-９]{12}$|^$/.test(value), i18n.t('validator.enter_num_digits', { num: 12 }));

jQuery.validator.addMethod('priceTypeNumber', (value, element) => {
  return mathUtil.toNumber(value) <= 2147483647;
}, i18n.t('nencho_validator.enter_under_4_bytes'));

jQuery.validator.addMethod('fileName', function (value, element) {
  if (/[\\\/:*?"<>|]/.test(value)) {
    return false;
  } else {
    return true;
  }
}, i18n.t('validator.invalid_character_for_attribute', { attribute: i18n.t('attributes.file_name') }));

jQuery.validator.addMethod('dynamicIntegerDigits', function (value, element) {
  const integerDigitsMaxLength = parseInt($(element).attr('integerDigitsMaxLength'));
  const unit = $(element).data('unit');
  const unitPosition = $(element).data('unit-position');
  if (value === '' || value === null) {
    return true;
  }
  // 小数点を含めて入力してしまっている時に桁数の警告を出さないようにするため、正規化したのち、負の符号を除去する
  const integerPart = removeUnitAndComma(normalizeCustomNumberValue(value), unit, unitPosition).replace(/-/g, '');
  // 桁数を数えて上限と比較する
  return integerPart.length <= integerDigitsMaxLength;
}, function (params, element) {
  const integerDigitsMaxLength = $(element).attr('integerDigitsMaxLength');
  return i18n.t('validator.enter_under_num_digits', { num: integerDigitsMaxLength });
});

// 整数のみを許容するバリデーション
jQuery.validator.addMethod('integerOnly', (value, element) => /^[0-9]*$/.test(value), i18n.t('validator.enter_integer'));

// 小数形式の場合の整数部分の桁数に関するバリデーション
// FIXME: デグレを避けるため整数形式と別のメソッドにしているが、共通の処理が多いためマージ後にリファクタリングする
jQuery.validator.addMethod('dynamicIntegerDigitsForDecimal', function (value, element) {
  // integerDigitsMaxLengthが設定されていない場合には整数部桁数上限の最大値の12を与える
  const integerDigitsMaxLength = parseInt($(element).attr('integerDigitsMaxLength')) || 12;
  const unit = $(element).data('unit').toString();
  const unitPosition = $(element).data('unit-position');
  if (value === '' || value === null) {
    return true;
  }
  // 単位とカンマを削除
  value = removeUnitAndComma(value, unit, unitPosition);
  // 正規化した小数から整数部分を取り出し、マイナス符号を削除
  const integerPart = normalizeCustomDecimalValue(value).split('.')[0].replace(/-/g, '');
  // 桁数を数えて上限と比較する
  return integerPart.length <= integerDigitsMaxLength;
}, function (params, element) {
  // integerDigitsMaxLengthが設定されていない場合には整数部桁数上限の最大値の12を与える
  const integerDigitsMaxLength = parseInt($(element).attr('integerDigitsMaxLength')) || 12;
  return i18n.t('validator.enter_num_digit_integer_part', { num: integerDigitsMaxLength });
});

// 小数形式の場合の小数部分の桁数に関するバリデーション
jQuery.validator.addMethod('dynamicDecimalDigits', function (value, element) {
  // decimalPlacesが設定されていない場合には小数点以下桁数の最大値の3を与える
  const decimalPlaces = parseInt($(element).attr('decimalPlaces')) || 3;
  const unit = $(element).data('unit').toString();
  const unitPosition = $(element).data('unit-position');
  if (value === '' || value === null || !value.includes('.')) {
    return true;
  }
  // 単位とカンマを削除
  value = removeUnitAndComma(value, unit, unitPosition);
  // 正規化した小数から小数部分を取り出す(小数点がない場合は桁数を0とする)
  const decimalValue = normalizeCustomDecimalValue(value).split('.')[1] || '';
  // 桁数を数えて上限と比較する
  return decimalValue.length <= decimalPlaces;
}, function (params, element) {
  // decimalPlacesが設定されていない場合には小数点以下桁数の最大値の3を与える
  const decimalPlaces = parseInt($(element).attr('decimalPlaces')) || 3;
  return i18n.t('validator.enter_num_digit_decimal_part', { num: decimalPlaces });
});

// ファイルに関わるバリデーション
// - 1bytes 以上 10MiB以内の範囲外の場合、エラーとする
// - 拡張子がHEICの場合、エラーとする
jQuery.validator.addMethod('fileUpload', function (value, element, index) {
  if (element.files.length == 0) {
    return true;
  }

  const file = element.files[0];
  const MAX_FILE_SIZE = 10 * 1024 * 1024; // 10MiB
  const MIN_FILE_SIZE = 1; // 1B

  if (file.size > MAX_FILE_SIZE || file.size < MIN_FILE_SIZE) {
    $.validator.messages.fileUpload = i18n.t('validator.file_size_under_10mb');
    return false;
  }

  // HEICファイルの場合、エラーとする
  const fileNameSplit = file.name.split('.');

  if (fileNameSplit.length < 2 || fileNameSplit.pop().toLowerCase() == 'heic') {
    $.validator.messages.fileUpload = i18n.t('validator.invalid_file_type');
    return false;
  }

  return true;
}, '');

// 整数のみを許容するバリデーション
jQuery.validator.addMethod('integerOnly', (value, element) => /^[0-9]*$/.test(value), i18n.t('validator.enter_integer'));

// ファイルに関わるバリデーション
jQuery.validator.addMethod('fileRequired', function (value, element, index) {
  // 必須にしているか
  const isRequired = element.getAttribute('isRequired') == 'true';
  // BGFileStoreにファイルがアップロードしていない状態か
  const isNotUploaded = element.getAttribute('isUploaded') == 'false';
  // ファイルが添付されていない状態か
  const isNotFileAttached = element.files.length == 0;
  // 上記3つの条件がtrueとなる場合のみ、エラーする
  return !(isRequired && isNotUploaded && isNotFileAttached);
}, i18n.t('validator.is_required'));

// 日付に関わるバリデーション
// rules に `dateFormValid: '対象のカラム名'` を追加し使用する
jQuery.validator.addMethod('dateFormValid', function (value, element, key) {
  if (!value) return true;

  try {
    // repeater 使用箇所で入力フォームを特定するインデックスを用意 (repeater なしのフォームでは仮の値を代入)
    const index = (element.name.match(/\d+/) || ['0'])[0];

    // バリデーションをカラムごとに定義しマスタとして保持する
    // `type` は比較対象の日付より前(before)、より後(after)、以前(until)、以降(from)を示す
    // 日付(target)もしくはセレクタ(selector)で比較対象の日付を取得する
    const dateFormValidMaster = {
      // TODO: 特定の入力欄に対する定義として書かれているが、汎用的に使えるものはまとめる
      //       リファクタリングは、フロントエンドのテストが充実するまでは実施しない

      // 生年月日
      // NOTE: `birthday` は、過去日かつ1900/1/1以降の日付についての汎用的なバリデータとして利用されている
      birthday: [
        {
          type: 'after',
          target: '1900/01/01',
          message: i18n.t('validator.date_form.base_date'),
        },
        {
          type: 'before',
          target: new Date(),
          message: i18n.t('validator.date_form.before_today'),
        },
      ],
      // 契約開始日
      employment_contract_start_on: [
        {
          type: 'until',
          selector: '#employee_employee_employment_attributes_employment_contract_end_on',
          message: i18n.t('validator.date_form.before_contract_end_on'),
        },
      ],
      // 契約終了日
      employment_contract_end_on: [
        {
          type: 'from',
          selector: '#employee_employee_employment_attributes_employment_contract_start_on',
          message: i18n.t('validator.date_form.after_contract_start_on'),
        },
      ],
      // 標準報酬月額改定年月
      payrolls_updated_on: [
        {
          type: 'after',
          selector: '#employee_employee_enroll_attributes_hired_at',
          message: i18n.t('validator.date_form.after_hired_at'),
        },
        {
          type: 'until',
          target: new Date((new Date()).setFullYear((new Date()).getFullYear() + 1)),
          message: i18n.t('validator.date_form.within_1_year'),
        },
      ],
      // 入社年月日
      // NOTE: `hired_at` は、1900/1/1以降の日付についての汎用的なバリデータとして利用されている
      hired_at: [
        {
          type: 'after',
          target: '1900/01/01',
          message: i18n.t('validator.date_form.base_date'),
        },
      ],
      // 退職・解雇・死亡年月日
      leaved_at: [
        {
          type: 'from',
          selector: '#employee_employee_enroll_attributes_hired_at',
          message: i18n.t('validator.date_form.from_hired_at'),
        },
      ],
      // 通勤開始日
      commute_start_on: [
        {
          type: 'after',
          target: '1900/01/01',
          message: i18n.t('validator.date_form.base_date'),
        },
      ],
      // 社会保険の資格取得年月日
      shakai_hoken_qualified_on: [
        {
          type: 'until',
          target: new Date(),
          message: i18n.t('validator.date_form.until_today'),
        },
        {
          type: 'before',
          selector: '#employee_shakai_hoken_attributes_disqualified_on',
          message: i18n.t('validator.date_form.before_disqualified'),
        },
      ],
      // 社会保険の資格喪失年月日
      shakai_hoken_disqualified_on: [
        {
          type: 'after',
          selector: '#employee_shakai_hoken_attributes_qualified_on',
          message: i18n.t('validator.date_form.after_qualified'),
        },
      ],
      // 雇用保険の資格取得年月日
      koyo_hoken_qualified_on: [
        {
          type: 'until',
          target: new Date(),
          message: i18n.t('validator.date_form.until_today'),
        },
        {
          type: 'from',
          target: '1974/12/28',
          message: i18n.t('validator.date_form.from_koyo_hoken_kofu_date'),
        },
        {
          type: 'before',
          selector: '#employee_koyo_hoken_attributes_disqualified_on',
          message: i18n.t('validator.date_form.before_disqualified'),
        },
      ],
      // 雇用保険の資格喪失年月日
      koyo_hoken_disqualified_on: [
        {
          type: 'after',
          selector: '#employee_koyo_hoken_attributes_qualified_on',
          message: i18n.t('validator.date_form.after_qualified'),
        },
      ],
      // 出産予定日
      expected_on: [
        {
          type: 'until',
          target: new Date((new Date()).setDate((new Date()).getDate() + 280)),
          message: i18n.t('validator.date_form.within_280_days'),
        },
      ],
      // 産前休業開始日
      maternity_leave_start_on: [
        {
          type: 'from',
          selector: '#employee_employee_enroll_attributes_hired_at',
          message: i18n.t('validator.date_form.from_hired_at'),
        },
        {
          type: 'before',
          selector: `input[name="employee[maternity_and_childcares_attributes][${index}][expected_on]"]`,
          message: i18n.t('validator.date_form.before_expected_birth_date'),
        },
      ],
      // 出産日
      birth_on: [
        {
          type: 'after',
          selector: '#employee_employee_enroll_attributes_hired_at',
          message: i18n.t('validator.date_form.after_hired_at'),
        },
      ],
      // 産後休業終了日
      maternity_leave_end_on: [
        {
          type: 'after',
          selector: `input[name="employee[maternity_and_childcares_attributes][${index}][birth_on]"]`,
          message: i18n.t('validator.date_form.after_birth_date'),
        },
        {
          type: 'after',
          selector: `input[name="employee[maternity_and_childcares_attributes][${index}][maternity_leave_start_on]"]`,
          message: i18n.t('validator.date_form.after_maternity_leave_start_on'),
        },
      ],
      // 育児休業開始日
      childcare_leave_start_on: [
        {
          type: 'after',
          selector: `input[name="employee[maternity_and_childcares_attributes][${index}][maternity_leave_end_on]"]`,
          message: i18n.t('validator.date_form.after_maternity_leave_end_on'),
        },
      ],
      // 育児休業終了日
      childcare_leave_end_on: [
        {
          type: 'after',
          selector: `input[name="employee[maternity_and_childcares_attributes][${index}][childcare_leave_start_on]"]`,
          message: i18n.t('validator.date_form.after_childcare_leave_start_on'),
        },
      ],
      // 職場復帰予定日
      reinstatement_on: [
        {
          type: 'after',
          selector: `input[name="employee[maternity_and_childcares_attributes][${index}][birth_on]"]`,
          message: i18n.t('validator.date_form.after_birth_date'),
        },
        {
          type: 'after',
          selector: `input[name="employee[maternity_and_childcares_attributes][${index}][maternity_leave_end_on]"]`,
          message: i18n.t('validator.date_form.after_maternity_leave_end_on'),
        },
        {
          type: 'after',
          selector: `input[name="employee[maternity_and_childcares_attributes][${index}][childcare_leave_end_on]"]`,
          message: i18n.t('validator.date_form.after_childcare_leave_end_on'),
        },
      ],
      // 入学年月日
      student_admission_on: [
        {
          type: 'until',
          target: new Date(),
          message: i18n.t('validator.date_form.until_today'),
        },
      ],
      // 異動月日
      single_parent_on: [
        {
          type: 'until',
          target: new Date(),
          message: i18n.t('validator.date_form.until_today'),
        },
      ],
      // 監査ログ：ログ出力日（以降）
      audit_since: [
        {
          type: 'from',
          target: EXPORT_AUDIT_LOG_SPECIFY_EARLIEST_DATE,
          message: i18n.t('validator.date_form.after_audit_log_earliest_date'),
        },
        {
          type: 'until',
          target: new Date(),
          message: i18n.t('validator.date_form.until_today'),
        },
        {
          type: 'until',
          selector: `#to`,
          message: i18n.t('validator.date_form.before_audit_log_date'),
        },
      ],
      // 監査ログ：ログ出力日（以前）
      audit_until: [
        {
          type: 'from',
          target: EXPORT_AUDIT_LOG_SPECIFY_EARLIEST_DATE,
          message: i18n.t('validator.date_form.after_audit_log_earliest_date'),
        },
        {
          type: 'until',
          target: new Date(),
          message: i18n.t('validator.date_form.until_today'),
        },
        {
          type: 'from',
          selector: `#from`,
          message: i18n.t('validator.date_form.after_audit_log_date'),
        },
      ],
      // カスタムフィールドの日付項目
      custom_field_date: [
        {
          type: 'after',
          target: '1900/01/01',
          message: i18n.t('validator.date_form.base_date'),
        },
      ],
      // 任意時点従業員情報をダウンロードする際に指定する日付 @see app/views/backdoor/bitemporal_employee_export/new.html.slim
      export_target_date: [
        {
          type: 'after',
          target: '1900/01/01',
          message: i18n.t('validator.date_form.base_date'),
        },
      ],
      // 手続き最終更新日の絞り込みに指定する日付
      procedure_export_date_from: [
        {
          type: 'until',
          target: truncateDate(new Date()),
          message: i18n.t('validator.date_form.until_today'),
        },
        {
          type: 'until',
          selector: '#procedure_export_date_to',
          message: i18n.t('validator.date_form.before_end_date'),
        },
      ],
      procedure_export_date_to: [
        {
          type: 'until',
          target: truncateDate(new Date()),
          message: i18n.t('validator.date_form.until_today'),
        },
        {
          type: 'from',
          selector: '#procedure_export_date_from',
          message: i18n.t('validator.date_form.after_start_date'),
        },
      ],
    };

    const isInvalidValue = (date, type, targetDate) => {
      if ((type === 'before' && (date >= targetDate)) ||
        (type === 'until' && (date > targetDate)) ||
        (type === 'from' && (date < targetDate)) ||
        (type === 'after' && (date <= targetDate))) {
        return true;
      } else {
        return false;
      }
    };

    const date = truncateDate(new Date(value));
    // 日付のフォーマットチェック
    // yyyy/mm/dd or yyyy-mm-dd
    if (!value.match(/^(\d{4})\/(\d{1,2})\/(\d{1,2})$|^(\d{4})\-(\d{1,2})\-(\d{1,2})$/) || isNaN(date)) {
      $.validator.messages.dateFormValid = i18n.t('validator.enter_date_slash');
      return false;
    }
    const validPatterns = dateFormValidMaster[`${key}`];
    for (const validPattern of validPatterns) {
      // 日付の時
      if (validPattern.target) {
        const targetDate = truncateDate(new Date(validPattern.target));

        if (isInvalidValue(date, validPattern.type, targetDate)) {
          $.validator.messages.dateFormValid = validPattern.message;
          return false;
        }
      }
      // セレクタの時
      if ($(validPattern.selector).val()) {
        const targetDate = truncateDate(new Date($(validPattern.selector).val()));

        if (isInvalidValue(date, validPattern.type, targetDate)) {
          $.validator.messages.dateFormValid = validPattern.message;
          return false;
        }
      }
    }
  } catch {
    $.validator.messages.dateFormValid = i18n.t('validator.invalid_date');
    return false;
  }

  return true;
}, '');

// マイナンバーに関わるバリデーション
// 12桁の数字 + チェックデジット により判断する
// 参考: https://www.j-lis.go.jp/data/open/cnt/3/1282/1/H2707_qa.pdf
jQuery.validator.addMethod('myNumber', function (value, element) {
  // 任意の場合 (家族のマイナンバー） は未入力なら OK
  if (!element.required && value.length === 0) {
    return true;
  }

  // 前後の空白文字を取り除く
  value = value.toString().trim();
  // 12桁の数字
  if (!/^[ 　]*[0-9０-９]{12}[ 　]*$|^[ 　]*$/.test(value)) {
    return false;
  }

  // チェックデジット
  const sum = mathUtil.toNumber(value[10]) * 2 +
    mathUtil.toNumber(value[9]) * 3 +
    mathUtil.toNumber(value[8]) * 4 +
    mathUtil.toNumber(value[7]) * 5 +
    mathUtil.toNumber(value[6]) * 6 +
    mathUtil.toNumber(value[5]) * 7 +
    mathUtil.toNumber(value[4]) * 2 +
    mathUtil.toNumber(value[3]) * 3 +
    mathUtil.toNumber(value[2]) * 4 +
    mathUtil.toNumber(value[1]) * 5 +
    mathUtil.toNumber(value[0]) * 6;
  let checkDigitNumber = 11 - sum % 11;
  if (checkDigitNumber == 10 || checkDigitNumber == 11) {
    checkDigitNumber = 0;
  }
  if (mathUtil.toNumber(value[11]) == checkDigitNumber) {
    return true;
  } else {
    return false;
  }
}, i18n.t('validator.invalid_mynumber'));
